export const NEW_PRACTITIONER_REPORT_ITEM = 'NEW_PRACTITIONER_REPORT_ITEM';

export const PULL_PRACTITIONER_REPORT_ITEM = 'PULL_PRACTITIONER_REPORT_ITEM';

export const ASSIGN_PRACTITIONER_REPORT_ITEM_APPOINTMENT_TIME = 'ASSIGN_PRACTITIONER_REPORT_ITEM_APPOINTMENT_TIME';
export const ASSIGN_PRACTITIONER_REPORT_ITEM_DURATION = 'ASSIGN_PRACTITIONER_REPORT_ITEM_DURATION';
export const ASSIGN_PRACTITIONER_REPORT_ITEM_HEALTH_FUND = 'ASSIGN_PRACTITIONER_REPORT_ITEM_HEALTH_FUND';
export const ASSIGN_PRACTITIONER_REPORT_ITEM_TOTAL_SALE = 'ASSIGN_PRACTITIONER_REPORT_ITEM_TOTAL_SALE';
export const ASSIGN_PRACTITIONER_REPORT_ITEM_HICAPS = 'ASSIGN_PRACTITIONER_REPORT_ITEM_HICAPS';
export const ASSIGN_PRACTITIONER_REPORT_ITEM_EFTPOS = 'ASSIGN_PRACTITIONER_REPORT_ITEM_EFTPOS';
export const ASSIGN_PRACTITIONER_REPORT_ITEM_CASH = 'ASSIGN_PRACTITIONER_REPORT_ITEM_CASH';
export const ASSIGN_PRACTITIONER_REPORT_ITEM_VOUCHER = 'ASSIGN_PRACTITIONER_REPORT_ITEM_VOUCHER';
export const ASSIGN_PRACTITIONER_REPORT_ITEM_VOUCHER_NUMBER = 'ASSIGN_PRACTITIONER_REPORT_ITEM_VOUCHER_NUMBER';
export const ASSIGN_PRACTITIONER_REPORT_ITEM_ITEM = 'ASSIGN_PRACTITIONER_REPORT_ITEM_ITEM';
export const ASSIGN_PRACTITIONER_REPORT_ITEM_IS_REGULAR = 'ASSIGN_PRACTITIONER_REPORT_ITEM_IS_REGULAR';
export const ASSIGN_PRACTITIONER_REPORT_ITEM_IS_REMEDIAL = 'ASSIGN_PRACTITIONER_REPORT_ITEM_IS_REMEDIAL';
export const ASSIGN_PRACTITIONER_REPORT_ITEM_OVERTIME = 'ASSIGN_PRACTITIONER_REPORT_ITEM_OVERTIME';
export const ASSIGN_PRACTITIONER_REPORT_ITEM_MARGIN = 'ASSIGN_PRACTITIONER_REPORT_ITEM_MARGIN';
export const ASSIGN_PRACTITIONER_REPORT_ITEM_OFF = 'ASSIGN_PRACTITIONER_REPORT_ITEM_OFF';
export const ASSIGN_PRACTITIONER_REPORT_ITEM_PROVIDER = 'ASSIGN_PRACTITIONER_REPORT_ITEM_PROVIDER';
export const ASSIGN_PRACTITIONER_REPORT_ITEM_INSURANCE_COMPANY = 'ASSIGN_PRACTITIONER_REPORT_ITEM_INSURANCE_COMPANY';
export const ASSIGN_PRACTITIONER_REPORT_ITEM_PROVIDER_NUMBER = 'ASSIGN_PRACTITIONER_REPORT_ITEM_PROVIDER_NUMBER';

export const ASSIGN_PRACTITIONER_REPORT_ITEM_SURCHARGE = 'ASSIGN_PRACTITIONER_REPORT_ITEM_SURCHARGE';